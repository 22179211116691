import React from 'react'

function Loader() {
  return (
         <div className="bg-loader">
      Please wait ..
	        <div className="loader"></div>
        </div>
  )
    
  
}

export default Loader

import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/Sirtify-Logo.png';
import facebook from '../assets/images/facebook-f.svg';
import gooleplus from '../assets/images/goole-plus.svg';
import instagram from '../assets/images/instagram.svg';
import youtube from '../assets/images/youtube.svg';
import chaticon from '../assets/images/chat-icon.svg';
import UserService from '../services/user.service';
import TokenHelper from './TokenHelper';
import { userContext } from '../store';
import moment from 'moment';

function Footer() {
  var navigate = useNavigate();
  const [SubcriptionAlert, setSubcriptionAlert] = useState("");
  const { user, dispatch } = useContext(userContext);
  var getSubscriptionData = async () => {
    var token = TokenHelper.getToken();
    if (token !== null) {
      console.log("repeat");
      var response = await UserService.getSubscriptionData(user.tokendata)


      if (response.data.success) {


        var subcrip_data = response.data.data;

        // subscription end date
        const subscription_end_date = subcrip_data[0].subscription_end_date;

        // Get the current date
        const currentDate = moment();

        // Convert the timestamp to a moment object
        const targetDate = moment(subscription_end_date);

        // Calculate the difference in days
        const remainingDays = targetDate.diff(currentDate, 'days');

        // alert(remainingDays);

        // ====== msg status ====
        // 1: subcription packahe not select
        // 2: subcription expire soon
        // 3: subcription end

        if (remainingDays <= 3 && remainingDays >= 0) {
          if (remainingDays != 0) {
            setSubcriptionAlert("Subscription Package Expire within " + remainingDays + "days");
          } else {
            setSubcriptionAlert("Subscription Package Expire Today");
          }
        } else if (remainingDays < 0) {
          setSubcriptionAlert("Subscription Package End");
        }

        console.log(response.data)
      } else {
        setSubcriptionAlert("Buy a Subscription Package");
      }
    }
    else {
      console.log("not get token")
    }
  }
  useEffect(() => {
    getSubscriptionData();

  }, []);
  return (
    <>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-logo">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="footer-menu">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">Who We Are</Link>
                  </li>
                  <li>
                    <Link to="">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/security">Security</Link>
                  </li>
                  <li>
                    <Link to="/MemberSupport">Member Support</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="social-media">
                <Link to="">
                  <img src={facebook} alt="facebook" />
                </Link>
                <Link to="">
                  <img
                    src={gooleplus}
                    alt="google plus"
                    className="gooogle-plus"
                  />
                </Link>
                <a href="">
                  <img src={instagram} className="instagram" />
                </a>
                <a href="">
                  <img src={youtube} />
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="copy-right">
                <p>© Copyright 2024. All Right Reserved</p>
              </div>
            </div>
          </div>
        </div>
        <div className="chat-user">
          <img src={chaticon} alt="chat" />
        </div>
      </footer>


    </>
  )
}

export default Footer
import React, { useEffect, useState } from 'react';
// import OwlCarousel from 'react-owl-carousel';
import UserService from '../../services/user.service';
import OthersService from '../../services/others.service';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
// import { OwlCarousel } from 'react-owl-carousel';
import { useNavigate } from "react-router-dom"

import bannerimg from '../../assets/images/updatebanner.jpg';
import bannerdiagram from '../../assets/images/banner-diagram.png';
import providerimg from '../../assets/images/provider-img.png';
import nonprovider from '../../assets/images/non-provider.png';
import reliance from '../../assets/images/reliance.png';
import jetking from '../../assets/images/jetking.png';
import prical from '../../assets/images/prical.png';
import recycle from '../../assets/images/recycle.png';
import testmonialimg from '../../assets/images/testmonial-img.png';

import Loader from '../Loder';
// import About from './About';



function Index() {
    const [inputValue, setInputValue] = useState('');
    const [loader, setLoader] = useState(false);
    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
    const [HomeData, setHomeData] = useState([]);
    const handleSearchButtonClick = () => {
        // Toggle the state to open/close the search box
        setIsSearchBoxOpen(!isSearchBoxOpen);
    };
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSendClick = () => {
        if (inputValue) {
            console.log(inputValue, 'hhf')
            navigate(`/provider`, { state: { code: inputValue } })
        } else {
            toast.warning("this field required !!")
        }


    };
    const navigate = useNavigate();
    const [Dreamjobdata, setDreamjobdata] = useState([]);


    const Homedata = async () => {
        var responce = await OthersService.home();
        console.log(responce.data)

        if (responce.data.success) {
            setHomeData(responce.data.data)
        }
    }
    var getDreamjob = async () => {
        setLoader(true)
        var response = await UserService.getDreamjob()
        console.log("faqdata ", response.data.data)

        if (response.data.success) {

            setLoader(false)
            setDreamjobdata(response.data.data)
            console.log(response.data, 'responsedata')

        }
    }
    const [Testimonialdata, setTestimonialdata] = useState([]);
    var getTestimonial = async () => {

        var response = await UserService.getTestimonial()
        console.log("faqdata ", response.data.data)

        if (response.data.success) {
            setTestimonialdata(response.data.data)
            console.log(response.data, 'responsedata')

        }
    }
    console.log(Dreamjobdata, 'Dreamjobdata')
    useEffect(() => {

        getDreamjob();
        Homedata();
        getTestimonial();

    }, []);
    console.log(HomeData,'HomeData')
    return (
        <>

            <div className="banner">
                {/* <img className="bnnerimg" src={bannerimg} /> */}
                <img className="bnnerimg" src={HomeData.bgimage} />
                <div class="bg-overlay"></div>

                <div className="container banner-text-cont">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="bannertext">
                                <h1> 
                                {HomeData.topcontent}
                                    {/* A Network To Connect Providers,
                                    <br /> Recruiters, Human Resources,
                                    <br /> And Credentialing Services */}
                                </h1>
                                <ul>
                                    <li> {HomeData.innercontentone} </li>
                                    <li> {HomeData.innercontenttwo} </li>
                                    <li> {HomeData.innercontentthree}</li>
                                    <li> {HomeData.innercontentfour}</li>
                                </ul>
                                <Link to={HomeData.button_link} state={{ provider_status: true }} className="common-btn">
                                    {/* Sign Up */}{HomeData.button_name}
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-image">
                                {/* <img src={bannerdiagram} alt="diagram" /> */}
                                <img src={HomeData.mainimage} alt="diagram" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="providers sec-space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="secttion-title text-center">
                                <p>
                                {HomeData.basic_content}
                                    {/* Our database streamlines recruiting and credentialing for Doctors,
                                    Physician assistants, and Nurse practitioners. It cuts down on
                                    paperwork so you don’t need to upload the same documents over and
                                    over. */}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card provide-box">
                                {/* <img src={providerimg} alt="provider" /> */}
                                <img src={HomeData.pimage} alt="provider" />
                                <div className="provider-content">
                                    <h3>{HomeData.P_title}</h3>
                                    <h4>{HomeData.p_subtitle}</h4>
                                    <p>{HomeData.providercontent}
                                        {/* Create a profile and submit your information to SirtifyMe’s
                                        secure database. Send and accept connection requests through
                                        SirtifyMe, which means you decide which medical entities see
                                        your data. Companies only access profiles that providers share
                                        with them. */}
                                    </p>
                                    <Link to={HomeData.P_btn_link} state={{ provider_status: true }} className="common-btn">
                                    {HomeData.P_btn_name}
                                        {/* Sign Up */}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card provide-box">
                                {/* <img src={nonprovider} alt="provider" /> */}
                                <img src={HomeData.nimage} alt="provider" /> 
                                <div className="provider-content">
                                    <h3>{HomeData.n_title}</h3>
                                    <h4>{HomeData.n_subtitle}</h4>
                                    <p>
                                        {/* Create a profile and submit your information to SirtifyMe’s
                                        secure database. Send and accept connection requests through
                                        SirtifyMe, which means you decide which medical entities see
                                        your data. Companies only access profiles that providers share
                                        with them. */}
                                        {HomeData.nonprovidercontent}
                                    </p>
                                    <Link to={HomeData.n_btn_link} state={{ provider_status: false }} className="common-btn">
                                    {HomeData.n_btn_name}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="how-it-work">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="how-it-work-descrip">
                                <h2>{HomeData.how_work}</h2>
                                <p>
                                {HomeData.how_work_content}
                                </p>
                                <Link className="common-btn" onClick={handleSearchButtonClick}>
                                    {HomeData.P_search_btn}
                                </Link>
                                <Link className="common-btn non-provider" onClick={handleSearchButtonClick}>
                                {HomeData.n_search_btn}
                                </Link>
                                {isSearchBoxOpen && (
                                    <div className="search">
                                        <input type="text" value={inputValue} onChange={handleInputChange} />
                                        <button className="search-btn" onClick={handleSendClick}>Search</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="price sec-space" id='price'>
                <div className="container">



                    <div className="provider-area">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title text-center"><h2>{HomeData.pricing_area}</h2></div>

                            </div>
                        </div>

                        {/* old */}
                        {/* <div className="row">

                            <div className="col-lg-4">
                                <div className="card provider-boxes">


                                    <h3>For Providers</h3>
                                    <span>$9.99/Year</span>

                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="card provider-boxes">

                                    <h3>Non Providers</h3>

                                    <span>$300/Month</span>
                                    <p>For 50 Providers Or Less</p>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="card provider-boxes">

                                    <h3>Non Providers</h3>

                                    <span>$450/Month</span>
                                    <p>Per 100 Providers</p>

                                </div>
                            </div>


                        </div> */}

                        {/* new */}

                        <div className="row">

                            <div className="col-lg-12">
                                <div className=" provider-boxes" style={{ "textAlign": "center" }}>
                                    <h3>{HomeData.pricing_content}</h3>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>



            </div>
            <div className="dream-job sec-space">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="section-title text-center">
                                <h2>Find Your Dream Job Now</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {Dreamjobdata.length > 0 && <OwlCarousel
                                loop={true}
                                margin={10}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={3000}

                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    1000: {
                                        items: 3.5
                                    }

                                }}
                                className="owl-carousel owl-theme  dreasm-slider"
                            >
                                {Dreamjobdata.map((item, index) => {
                                    return (<>
                                        <div key={"job" + index} className="item">
                                            <div className="dream-box">
                                                <h3>{item.name}</h3>
                                                <p>{item.designation}</p>

                                                <div class="company-logos">
                                                    <img src={item.image} alt="company" />
                                                    {/* <img src={jetking} alt="company" />
                                                    <img src={prical} alt="company" />
                                                    <img src={recycle} alt="company" /> */}
                                                </div>

                                            </div>
                                        </div>
                                    </>)
                                })}
                            </OwlCarousel>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="testimonial sec-space">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="section-title text-center">
                                <h2>Testimonials</h2>
                               
                            </div>
                        </div>
                    </div>
                    {Testimonialdata.length>0 &&  <OwlCarousel
                        className="owl-theme testimonial-slider"
                        id="testimonial-slider"
                        items={1}
                        loop={false}
                        nav={false}
                        margin={20}
                        autoplay={true}
                        responsive={{
                            0: {
                                items: 1
                            },
                            576: {
                                items: 2
                            },
                            768: {
                                items: 2,
                              },
                              992: {
                                items: 2,
                              },
                              1200: {
                                items: 2,
                              },
                              1400: {
                                dots: false,
                                nav: true,
                                items: 2,
                              },
                              1600: {
                                dots: false,
                                nav: true,
                                items: 2,
                              },
                              1800: {
                                dots: false,
                                nav: true,
                                items: 2,
                              },
                        }}

                    >



                        {Testimonialdata.map((item, index) => {
                            return (<>
                                <div key={"testimonial_" + index} className="item">
                                    <div className="testimonial-box matchheight">
                                        <div className="testimonial-client">
                                            <div className="client-img">
                                                {/* <img src={item.image} alt="testmonial" /> */}
                                            </div>
                                            <div className="client-desig">
                                                <h4>{item.name}</h4>
                                                <span>{item.designation}</span>
                                            </div>
                                        </div>
                                        <div className="testimonial-content">
                                            <p>
                                                <p>
                                                    {item.testimonial}<Link to={'/testimonials'} state={{ t_id: item.testimonial_id }} className='view-more-link'>View More</Link>
                                                </p>
                                            </p>
                                        </div>

                                    </div>
                                </div>

                            </>)
                        })}

                    </OwlCarousel>}
                </div>
            </div>

        </>
    )
}

export default Index

import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Table, Button } from 'react-bootstrap';
function MemberSupport() {

    return(

        <>
   <section className="security-sec">
                <Container>
                    <Row>
                        <Col lg={12}>
                        <div className='inner_content'>

                        <h1>Member Support</h1>
                            
                           <div className="membersupport-div">
                           <p>Please contact us with any questions. <a href="mailto:Support@sirtifyme.com">Support@sirtifyme.com,</a><br/>or call or text via</p>
                           <p>WhatsApp <a href="tel:+1 1305 518 6177">+1 1305 518 6177</a></p>
                           </div>
                            </div>
                           
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )

}


export default MemberSupport;
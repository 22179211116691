import React, { useEffect, useState } from "react";
import othersService from "../../services/others.service";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import aboutussidebg from "../../assets/images/about-us-side-bg.png";
import aboutinner from "../../assets/images/about-banner.png";
import blankavtar from "../../assets/images/blank-profile-picture.png";
import golda from "../../assets/images/golda.jpg"
function Index() {
    const [Content, setContent] = useState("");
    const [Countdata, setcountdata] = useState("");
    const About = async () => {
        var responce = await othersService.about();
        console.log(responce.data);

        if (responce.data.success) {
            setContent(responce.data.data.content);
        }
    };

    const Aboutcount = async () => {
        var responce = await othersService.aboutcountuser();
        console.log(responce.data);

        if (responce.data.success) {
            setcountdata(responce.data.data);
        }
    };
    console.log(Countdata, "Countdata");
    useEffect(() => {
        About();
        Aboutcount();
    }, []);

    return (
        // ===========Inner banner start ============//

        <>
            {/* ====== Inner banner start ======== */}
            <section
                className="inner-page"
                style={{ backgroundImage: `url('${aboutinner}')` }}
            >
                <div className="container">
                    <div className="inner-content">
                        <h1>Who We Are</h1>
                    </div>
                </div>
            </section>
            {/* ===========Inner banner end ============*/}
            {/*=========About start ======*/}
            <section className="about-sec">
                <div className="container">
                    <div className="about-inner">
                        <div className="row">
                            <div className="col-md-5 align-self-center">
                                <div className="about-left">
                                    <img src={aboutussidebg} alt="" />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="about-right">
                                    {/* <h3>About Us</h3> */}
                                    {parse(Content)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-inner mt-4">
                        <div className="row  flex-column-reverse flex-sm-row">
                            <div className="col-md-7">
                                <div className="about-right mt-4">
                                    <p><em><strong>About the Founder</strong></em></p>
                                    <p>My name is Golda Schreiber, the founder of SirtifyMe. I’m a PA with over 12 years of experience in urgent care, emergency medicine, and telehealth. Besides working full time, I am a busy mother, which means I constantly look for ways to simplify my life. There is one area in particular that I strive to improve—not just for myself, but for all medical providers.

                                        <br />
                                        In my healthcare journey, I’ve seen firsthand how much time is spent on job applications and uploading forms. Over the course of my career, I have applied to various medical positions. Each time, I am required to send all of my licenses and certifications to recruiters, credentialing companies, and HR departments. It’s a tedious process that often results in delays at both ends, costing companies money that can be saved with a little electronic wand-waving.

                                        <br />
                                        I started wondering why providers like me must submit the same information over and over again. Why can’t there be one centralized national database for all our information? Remembering the PA school application process, I couldn’t help but appreciate its efficiency: I submitted my application online, picked my preferred schools, and gave them access to my application. Pretty soon, the interview invitations started coming in. It was a seamless experience that made a deep impression on me.

                                        <br />
                                        Moving forward, it was simple. I took my experience of applying to PA school, coupled with my frustration with job applications and the credentialing process, and that’s how SirtifyMe began.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-5 align-self-center">
                                <div className="about-left btm-abt-lft mt-4">
                                    <img src={golda} alt="avtar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=========About end ======*/}
            {/*========= Counter Start ======*/}
            <section className="counter">
                <div className="container">
                    <div className="counter-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="counter-no">
                                    <h2 className="count" data-count={150}>
                                        {Countdata.hospital_count}
                                    </h2>
                                    <h5>Hospital</h5>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="counter-no">
                                    <h2 className="count" data-count={800}>
                                        {Countdata.Private_Recruiters_count}
                                    </h2>
                                    <h5>Private Recruiters</h5>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="counter-no">
                                    <h2 className="count" data-count={1120}>
                                        {Countdata.Physicians_count}
                                    </h2>
                                    <h5>Physicians</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*========= Counter End ======*/}
        </>
    );
}

export default Index;


import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import AdminService from '../../services/admin.service';

import { useContext } from 'react';
import { userContext } from '../../../store';
import Loader from '../Loder';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import FileBase64 from 'react-file-base64';
import view from '../../assets/images/view.png';
// import { licenseSchema } from '../Schemas';
import { licenseDataSchema } from '../Schemas';
import { referencesSchema } from '../Schemas'
import TokenHelper from '../TokenHelper';
import { useFieldArray, useForm } from "react-hook-form";
import RichTextEditor from 'react-rte';



function AboutusPage() {


  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [filename, setFilename] = useState("Select Your Document");
  const [Imagename,setImagename] = useState('')
  // this.setState({ value: RichTextEditor.createValueFromString(text, 'html') });

  const [text, setText] = useState("");
  const [Idvalue, setIdvalue] = useState("");
  const [loader, setLoader] = useState(false);
  const handleEditorChange = (newValue) => {

    setValue(newValue);
    setText(newValue.toString('html'));
   
  };
  
  var getAboutusData = async () => {

    var token = TokenHelper.getToken();


    //  alert(id)
    if (token !== null) {
      console.log("repeat");
      var response = await AdminService.getAboutus(token)

      if (response.data.success) {
        // setText(response.data.data.content);
        setIdvalue(response.data.data.about_us_id);
        setValue(RichTextEditor.createValueFromString(response.data.data.content,'html'));
        setFilename(response.data.data.image)
      //   reset({
         
      //     content: response.data.data.content
      // })


        console.log(response.data)
      }
    }
    else {
      console.log("not get token")
    }
  }
  //  getProfileData();
  useEffect(() => {
    getAboutusData();

  }, []);
 
  const dataSubmit = async (data) => {

    var fdata = new FormData();
    fdata.append("content", text);
    fdata.append("about_us_id",Idvalue)
    if (data.image !== null) {
      fdata.append("image", data.image[0]);
  }


    var response = await AdminService.PostAboutus(fdata);
    // alert(response,'response')

    console.log(response.data.message)
    // if (response.data.error) {
    //   setLoader(false);
    //   toast.error(response.data.error.usererror)
    // }
    if (response.data.success) {
      setLoader(false);
      toast.success(response.data.message)
    }else{
      toast.error(response.data.message)
    }

    console.log(response.data)
    reset()
    await getAboutusData();



  }
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm({
   
    mode: "all"
  });
  console.log(text, ' text')
  console.log(value, ' value')
  return (
    <>
      <div className='about_title'>
        <h3>About Us</h3>
      </div>
      <form action="" method="post" onSubmit={handleSubmit(dataSubmit)}>
      <RichTextEditor className='about_text' value={value} onChange={handleEditorChange} /><br></br>
      <div className="col-md-4">
        <span>Upload Image</span>
      <input type="file" className="form-control" id="file-upload-field" {...register("image")} onChange={e => setFilename((e.target.files && e.target.files[0].name))} accept=".jpg,.png,.jpeg" />
  
      </div>
      <div>
        <img src={filename}/>
      </div>
      <div>
      </div>
      <div className="col-md-12 text-center about_btn">
        <button type="submit" className="submit" >Save</button>
      </div>
      </form>
      {loader && <Loader />}
    </>

  );
}

export default AboutusPage

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AdminService from "../../services/admin.service";

import { useContext } from "react";
import { userContext } from "../../../store";
import Loader from "../Loder";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import FileBase64 from "react-file-base64";
import view from "../../assets/images/view.png";
// import { licenseSchema } from '../Schemas';
import { licenseDataSchema } from "../Schemas";
import { referencesSchema } from "../Schemas";
import TokenHelper from "../TokenHelper";
import { useFieldArray, useForm } from "react-hook-form";
import RichTextEditor from "react-rte";

function HomePage() {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [filename, setFilename] = useState("Select Your Document");
  const [bgname, setBgname] = useState("Select Your Document");
  const [provider, setProvidername] = useState("Select Your Document");
  const [nonprovidername, setNonprovidername] = useState("Select Your Document");
  const [Imagename, setImagename] = useState("");
  // this.setState({ value: RichTextEditor.createValueFromString(text, 'html') });

  const [text, setText] = useState("");
  const [Idvalue, setIdvalue] = useState("");
  const [loader, setLoader] = useState(false);


  var getHomeData = async () => {
    var token = TokenHelper.getToken();

    //  alert(id)
    if (token !== null) {
      console.log("repeat");
      var response = await AdminService.getHome(token);
      console.log(response.data, 'response_get_home')
      if (response.data.success) {
        // setText(response.data.data.content);
        setIdvalue(response.data.data.home_id);

        setImagename(response.data.data.image);
        reset({
          topcontent: response.data.data.topcontent,
          providercontent: response.data.data.providercontent,
          nonprovidercontent: response.data.data.nonprovidercontent,
          innercontentone: response.data.data.innercontentone,
          innercontenttwo: response.data.data.innercontenttwo,
          innercontentthree: response.data.data.innercontentthree,
          innercontentfour: response.data.data.innercontentfour,

          button_name: response.data.data.button_name,
          button_link: response.data.data.button_link,
          P_btn_link: response.data.data.P_btn_link,
          P_btn_name: response.data.data.P_btn_name,
          n_btn_link: response.data.data.n_btn_link,
          n_btn_name: response.data.data.n_btn_name,
          basic_content: response.data.data.basic_content,
          how_work: response.data.data.how_work,
          how_work_content: response.data.data.how_work_content,
          pricing_area: response.data.data.pricing_area,
          pricing_content: response.data.data.pricing_content,

          n_title: response.data.data.n_title,
          P_title: response.data.data.P_title,
          p_subtitle: response.data.data.p_subtitle,
          n_subtitle: response.data.data.n_subtitle,
          n_search_btn: response.data.data.n_search_btn,
          P_search_btn: response.data.data.P_search_btn,


        })
        setFilename(response.data.data.mainimage)
        setBgname(response.data.data.bgimage)
        setProvidername(response.data.data.pimage)
        setNonprovidername(response.data.data.nimage)

        console.log(response.data);
      }
    } else {
      console.log("not get token");
    }
  };
  //  getProfileData();
  console.log(bgname, 'bgname')
  console.log(provider, 'provider')
  console.log(filename, 'filename')
  console.log(nonprovidername, 'nonprovidername')
  useEffect(() => {
    getHomeData();
  }, []);

  const dataSubmit = async (data) => {
    console.log(data, 'data')
    var fdata = new FormData();
    fdata.append("home_id", Idvalue);
    fdata.append("topcontent", data.topcontent);
    fdata.append("innercontentone", data.innercontentone);
    fdata.append("innercontenttwo", data.innercontenttwo);
    fdata.append("innercontentthree", data.innercontentthree);
    fdata.append("innercontentfour", data.innercontentfour);
    fdata.append("providercontent", data.providercontent);
    fdata.append("nonprovidercontent", data.nonprovidercontent);
    fdata.append("button_name", data.button_name);
    fdata.append("button_link", data.button_link);
    fdata.append("P_btn_link", data.P_btn_link);
    fdata.append("P_btn_name", data.P_btn_name);
    fdata.append("P_title", data.P_title);
    fdata.append("p_subtitle", data.p_subtitle)
    fdata.append("n_subtitle", data.n_subtitle)
    fdata.append("P_search_btn", data.P_search_btn);

    fdata.append("n_btn_link", data.n_btn_link);
    fdata.append("n_btn_name", data.n_btn_name);
    fdata.append("n_title", data.n_title);
    fdata.append("n_search_btn", data.n_search_btn);
    fdata.append("basic_content", data.basic_content);
    fdata.append("how_work", data.how_work);
    fdata.append("how_work_content", data.how_work_content);
    fdata.append("pricing_area", data.pricing_area);
    fdata.append("pricing_content", data.pricing_content);


    if (data.image !== null) {
      fdata.append("mainimage", data.image[0]);
    }
    if (data.bgimage !== null) {
      fdata.append("bgimage", data.bgimage[0]);
    }
    if (data.pimage !== null) {
      fdata.append("pimage", data.pimage[0]);
    }
    if (data.nimage !== null) {
      fdata.append("nimage", data.nimage[0]);
    }
    var response = await AdminService.PostHome(fdata);
    // alert(response,'response')

    console.log(response.data.message);
    // if (response.data.error) {
    //   setLoader(false);
    //   toast.error(response.data.error.usererror)
    // }
    if (response.data.success) {
      console.log(response.data)
      setLoader(false);
      toast.success(response.data.message);


      // setFiledata(response.data.data.image)
      // setTestimonialData(response.data.data)
    } else {
      toast.error(response.data.message);
    }

    console.log(response.data);
    reset();
    await getHomeData();
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    mode: "all",
  });
  console.log(text, " text");
  console.log(value, " value");
  return (
    <>
      <div className="about_title">
        <h3>Home</h3>
      </div>
      <form action="" method="post" onSubmit={handleSubmit(dataSubmit)}>
        <div className="col-md-8">
          <div className="form-group">
            <label>Top Content</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("topcontent")}
            />
            <p className="form-field-error">{errors.topcontent?.message}</p>
          </div>
          <div className="form-group">
            <label>Inner Content1</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("innercontentone")}
            />
            <p className="form-field-error">{errors.innercontentone?.message}</p>
          </div>
          <div className="form-group">
            <label>Inner Content2</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("innercontenttwo")}
            />
            <p className="form-field-error">{errors.innercontenttwo?.message}</p>
          </div>
          <div className="form-group">
            <label>Inner Content3</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("innercontentthree")}
            />
            <p className="form-field-error">{errors.innercontentthree?.message}</p>
          </div>
          <div className="form-group">
            <label>Inner Content4</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("innercontentfour")}
            />
            <p className="form-field-error">{errors.innercontentfour?.message}</p>
          </div>
          <div className="form-group">
            <label>provider Sub Title</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("p_subtitle")}
            />
            <p className="form-field-error">{errors.p_subtitle?.message}</p>
          </div>
          <div className="form-group">
            <label>non provider Sub Title</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("n_subtitle")}
            />
            <p className="form-field-error">{errors.n_subtitle?.message}</p>
          </div>




          <div className="form-group">
            <label>provider content</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("providercontent")}
            />
            <p className="form-field-error">{errors.providercontent?.message}</p>
          </div>
          <div className="form-group">
            <label>non provider content</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("nonprovidercontent")}
            />
            <p className="form-field-error">{errors.nonprovidercontent?.message}</p>
          </div>

          <div className="form-group">
            <label>Button Name</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("button_name")}
            />
            <p className="form-field-error">{errors.button_name?.message}</p>
          </div>
          <div className="form-group">
            <label>Button Link</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("button_link")}
            />
            <p className="form-field-error">{errors.button_link?.message}</p>
          </div>
          <div className="form-group">
            <label>Provider Button Link</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("P_btn_link")}
            />
            <p className="form-field-error">{errors.P_btn_link?.message}</p>
          </div>
          <div className="form-group">
            <label>Provider Button Name</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("P_btn_name")}
            />
            <p className="form-field-error">{errors.P_btn_name?.message}</p>
          </div>
          <div className="form-group">
            <label>NonProvider Button Link</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("n_btn_link")}
            />
            <p className="form-field-error">{errors.n_btn_link?.message}</p>
          </div>
          <div className="form-group">
            <label>NonProvider Button Name</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("n_btn_name")}
            />
            <p className="form-field-error">{errors.n_btn_name?.message}</p>
          </div>
          <div className="form-group">
            <label>Basic Content</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("basic_content")}
            />
            <p className="form-field-error">{errors.basic_content?.message}</p>
          </div>
          <div className="form-group">
            <label>How It Work</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("how_work")}
            />
            <p className="form-field-error">{errors.how_work?.message}</p>
          </div>
          <div className="form-group">
            <label>How It Work Content</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("how_work_content")}
            />
            <p className="form-field-error">{errors.how_work_content?.message}</p>
          </div>
          <div className="form-group">
            <label>Pricing Area</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("pricing_area")}
            />
            <p className="form-field-error">{errors.pricing_area?.message}</p>
          </div>
          <div className="form-group">
            <label>Pricing Area content</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("pricing_content")}
            />
            <p className="form-field-error">{errors.pricing_content?.message}</p>
          </div>
          <div className="form-group">
            <label>Provider Title</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("P_title")}
            />
            <p className="form-field-error">{errors.P_title?.message}</p>
          </div>
          <div className="form-group">
            <label>NonProvider Title</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("n_title")}
            />
            <p className="form-field-error">{errors.n_title?.message}</p>
          </div>
          <div className="form-group">
            <label>Provider Search Button</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("P_search_btn")}
            />
            <p className="form-field-error">{errors.P_search_btn?.message}</p>
          </div>
          <div className="form-group">
            <label>Nonprovider Search Button</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              {...register("n_search_btn")}
            />
            <p className="form-field-error">{errors.n_search_btn?.message}</p>
          </div>





        </div>
        <div className="col-md-4 admin-center-align">
          <span>Upload Image</span>
          <input
            type="file"
            className="form-control"
            id="file-upload-field"
            {...register("image")}
            onChange={(e) =>
              setFilename(e.target.files && e.target.files[0].name)
            }
            accept=".jpg,.png,.jpeg"
          />
          {filename ? <img
            className="img-responsive rounded-circle profile_img"
            src={filename}
            alt=""
          /> : ''}
        </div>

        <div>
          <div className="col-md-4 admin-center-align">
            <span>Upload background Image</span>
            <input
              type="file"
              className="form-control"
              id="file-upload-field"
              {...register("bgimage")}
              onChange={(e) =>
                setBgname(e.target.files && e.target.files[0].name)
              }
              accept=".jpg,.png,.jpeg"
            />
            {bgname ? <img
              className="img-responsive rounded-circle profile_img"
              src={bgname}
              alt=""
            /> : ''}
          </div>
          <div className="col-md-4 admin-center-align">
            <span>Provider Image</span>
            <input
              type="file"
              className="form-control"
              id="file-upload-field"
              {...register("pimage")}
              onChange={(e) =>
                setProvidername(e.target.files && e.target.files[0].name)
              }
              accept=".jpg,.png,.jpeg"
            />
            {provider ? <img
              className="img-responsive rounded-circle profile_img"
              src={provider}
              alt=""
            /> : ''}
          </div>
          <div className="col-md-4 admin-center-align">
            <span>NonProvider Image</span>
            <input
              type="file"
              className="form-control"
              id="file-upload-field"
              {...register("nimage")}
              onChange={(e) =>
                setNonprovidername(e.target.files && e.target.files[0].name)
              }
              accept=".jpg,.png,.jpeg"
            />
            {nonprovidername ? <img
              className="img-responsive rounded-circle profile_img"
              src={nonprovidername}
              alt=""
            /> : ''}

          </div>
        </div>
        <div className="col-md-12 text-center about_btn">
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </form>
      {loader && <Loader />}
    </>
  );
}

export default HomePage;

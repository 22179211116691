
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Table, Button } from 'react-bootstrap';
function Security() {

    return(

        <>
   <section className="security-sec">
                <Container>
                    <Row>
                        <Col lg={12}>
                        <div className='inner_content'>

                        <h1>Security</h1>
                            
                            <p>At <a href="https://sirtifyme.com/">Sirtifyme.com</a> we take security and privacy very seriously. It is our commitment to you to ensure the security and privacy of all customer records against any foreseen dangers or risk. <a href="https://sirtifyme.com/">Sirtifyme.com</a> does not share any personal information with any external entities unless required by law or authorized to do so. We employ industry standards and best practices to secure all customer data against unauthorized access.</p>
                            </div>
                           
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )

}


export default Security;
import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Image, NavLink, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { userContext } from '../store';
import { LinkContainer } from 'react-router-bootstrap';
import Loader from '../pages/Loder';
import UserService from "../services/user.service";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from '../assets/images/Sirtify-Logo.png';
import facebook from '../assets/images/facebook-f.svg';
import gooleplus from '../assets/images/goole-plus.svg';
import instagram from '../assets/images/instagram.svg';
import youtube from '../assets/images/youtube.svg';
import search from '../assets/images/search.svg';
import { AuthContext } from '../App';

function Header() {
  var navigate = useNavigate();
  // const {user}=useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const { user, dispatch } = useContext(userContext);
  console.log(user)
  const Logout = () => {
    localStorage.clear();
    dispatch({ type: "reset", valuse: "" });
    // localStorage.clear(); 
    // setIsLoggedIn(true);
    navigate('/')
    window.location.reload();
    toast.success("Logout Successfully")
  };
  const Accountdetele = async () => {

    // localStorage.clear(); 
    setLoader(true);
    var fdata = new FormData();
    fdata.append("account_status", 0);
    var response = await UserService.updateaccount(fdata);
    // setIsLoggedIn(true);
    if (response.data.success) {
      setLoader(false);
      localStorage.clear();
      dispatch({ type: "reset", valuse: "" });
      navigate('/')
      toast.success(response.data.message)
      // window.location.reload();

    } else {
      setLoader(false);
      toast.error(response.data.message)
    }
  };




  // const [isLoggedIn, setIsLoggedIn] = useState();
  return (
    <>
      <header className="navigation">
        <div className="top-header">
          <div className="container">
            <div className="top-header-row">
              <div className="social-media">
                <Link to="">
                  <img src={facebook} alt="facebook" />
                </Link>
                <Link to="">
                  <img
                    src={gooleplus}
                    alt="google plus"
                    className="gooogle-plus"
                  />
                </Link>
                <Link to="">
                  <img src={instagram} className="instagram" />
                </Link>
                <Link to="">
                  <img src={youtube} />
                </Link>
              </div>

              {!user.tokendata && (<div className="user-link">
                <Link to="">Login
                  <ul class="submenu">
                    <li><Link to="/login" state={{ provider_status: true }} >Login As A Provider</Link></li>
                    <li><Link to="/login" state={{ provider_status: false }}>Login As A Non Provider</Link></li>
                  </ul>
                </Link>
                <Link to="">Register
                  <ul class="submenu">
                    <li><Link to="/signup" state={{ provider_status: true }} >Register As A Provider</Link></li>
                    <li><Link to="/signup" state={{ provider_status: false }}>Register As A Non Provider</Link></li>
                  </ul>
                </Link>
              </div>
              )}

              {/* {user.tokendata && ( */}
                {/* <ul id="menu-bg">
                  <li>
                    <Button className="log-in-register logout" onClick={Logout} to="/">
                      Logout
                    </Button>
                  </li>
                </ul> */}
                 {user.tokendata && (
                  <div className="dropdown datapass">
                    <Link
                      className="btn btn-primary dropdown-toggle"
                      to={""}
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* Button trigger modal */}
                      {user.tokendata ? user?.name : "Name"}
    
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
    
                      <li>
                        {user.tokendata ? <Link className="dropdown-item" to={"/profile"}>
                          My Profile
                        </Link> : ''}
    
                      </li>
                     
                      <li>
                        {user.tokendata && (user.user_type == "1" || user.user_type == "2" || user.user_type == "3") && <Link className="dropdown-item" to={"/notification"}>
                          Connection Request
                        </Link >
                        }
                        {user.tokendata && (user.user_type == "4" || user.user_type == "5" || user.user_type == "6") && <Link className="dropdown-item" to={"/myconnection"}>
                          MyConnections
                        </Link >}
                      </li>
                      <li>
                        {user.tokendata && (user.user_type == "1" || user.user_type == "2" || user.user_type == "3") && <Link className="dropdown-item" to={"/requestlist"}>
                          Request History
                        </Link >
                        }
    
                      </li>
                      <li>
                        {user.tokendata && (user.user_type == "1" || user.user_type == "2" || user.user_type == "3") && <Link className="dropdown-item" to={"/connectionlist"}>
                          Connection List
                        </Link >
                        }
    
                      </li>
                      <li>
                        {user.tokendata && (user.user_type == "1" || user.user_type == "2" || user.user_type == "3") && <Link className="dropdown-item" onClick={Accountdetele}>
                          Delete Account
                        </Link >
                        }
    
                      </li>
                      <li>
                        {user.tokendata && <button className="dropdown-item" onClick={Logout}>
                          Logout
                        </button >}
                      </li>
                    </ul>
                  </div>
                  // <ul id="menu-bg">
                  //   <li>
                  //     <Button className="log-in-register" onClick={Logout} to="/">
                  //       Logout
                  //     </Button>
                  //   </li>
                  // </ul>
                )}


            

            </div>
          </div>
        </div>
        <div className="container">
          <div className="main-header">
            <div className="brand">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="header-right">
              <nav>
                <div className="nav-mobile">
                  <Link id="navbar-toggle" to="#">
                    <span />
                  </Link>
                </div>
                <ul className="nav-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">Who We Are</Link>
                  </li>
                  <li>
                    <a href="#price">Pricing</a>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                </ul>
              </nav>
              {/* <div className="search">
                <img src={search} alt="search" />
              </div> */}
              <div className="contact-btn">
                <Link to="/contact" className="common-btn">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

    </>
  )
}

export default Header